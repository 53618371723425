<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-card>
          <div style="display: flex">
            <h4
              style="
                margin: auto;
                color: #909399;
                margin-left: 20px;
                margin-right: 0px;
              "
            >
              Filtrar por barrio/sucursal:
            </h4>
            <maca-select-box
              style="
                margin: auto;
                width: 400px;
                margin-left: 20px;
                margin-right: 0px;
              "
              :url="urlSeccion"
              v-model="seccion"
              :clearable="true"
              :necesitaParams="true"
              :permitirInput="true"
              :getParams="getParamsSeccion"
              itemLabel="descripcion"
              placeholder="Seleccione barrio/sucursal"
            >
            </maca-select-box>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="14">
        <div v-loading="cargando">
          <!-- CALENDARIO -->
          <el-card style="padding: 20px">
            <h4
              style="
                color: #909399;
                margin-top: 0px;
                padding-top: 0px;
                margin-bottom: 30px;
              "
            >
              Turnos disponibles
            </h4>

            <div style="display: flex; margin-top: 5px; color: #606266">
              <div class="circuloVerde"></div>
              <span> + 75%</span>

              <div class="circuloAmarillo" style="margin-left: 10px"></div>
              <span> + 50%</span>

              <div class="circuloNaranja" style="margin-left: 10px"></div>
              <span> + 25%</span>

              <div class="circuloRojo" style="margin-left: 10px"></div>
              <span> - 25%</span>
            </div>
            <!-- <div style="display: flex; margin-top: 5px; color: #606266">
              <div class="circuloAmarillo"></div>
              <span> + 50%</span>
            </div>
            <div style="display: flex; margin-top: 5px; color: #606266">
              <div class="circuloNaranja"></div>
              <span> + 25%</span>
            </div>
            <div style="display: flex; margin-top: 5px; color: #606266">
              <div class="circuloRojo"></div>
              <span> - 25%</span>
            </div> -->
            <div
              style="
                display: flex;
                margin-top: 5px;
                margin-bottom: 30px;
                color: #606266;
              "
            >
              <span
                style="color: #805ad5; margin-right: 10px; margin-left: 8px"
              >
                &bullet;</span
              >
              <span> Turnos de retiro</span>
            </div>
            <vc-date-picker
              :rows="2"
              is-expanded
              :attributes="attrs"
              v-model="fecha"
            ></vc-date-picker>
          </el-card>
        </div>
      </el-col>
      <el-col :span="10">
        <div v-loading="cargandoMetrica">
          <!-- METRICAS -->
          <el-card style="padding: 20px">
            <el-row>
              <el-col :span="16">
                <Pie
                  v-if="metricas"
                  :chart-options="chartOptions"
                  :chart-data="chartDataPie"
                />
              </el-col>
            </el-row>
            <div style="margin-top: 20px">
              <Bar
                v-if="metricas"
                :chart-options="chartOptions"
                :chart-data="chartDataBar"
                :height="200"
              />
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="css"></style>

<script>
import { Bar } from "vue-chartjs/legacy";
import { Pie } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
  ArcElement,
  Plugin,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement
);

export default {
  name: "home",
  components: { Bar, Pie },
  data() {
    return {
      fecha: null,
      cargando: false,
      cargandoMetrica: false,
      metricas: null,
      urlSeccion: "/seccion/obtenerTodos",
      seccion: null,

      //calendario
      attrs: [
        {
          key: "today",
          highlight: {
            fillMode: "light",
          },
          dot: true,
          dates: new Date(),
        },
      ],
      chartDataBar: null,
      chartDataPie: null,
      chartOptions: {
        responsive: true,
      },
    };
  },
  created() {
    this.obtenerTodosLosTurnos();
    this.obtenerMetricas();
  },
  methods: {
    getParamsSeccion(query) {
      let params = { descripcion: query };
      return params;
    },
    async obtenerTodosLosTurnos() {
      await this.obtenerTurnos();
      await this.obtenerRangoRetiro();
    },
    async obtenerTurnos() {
      this.cargando = true;
      const hoy = new Date();
      const sesentaDias = new Date();
      sesentaDias.setDate(hoy.getDate() + 60);

      let params = {
        fechaDesde: hoy,
        fechahasta: sesentaDias,
        sinPaginar: 1,
      };

      if (this.seccion) {
        params.seccionID = this.seccion.id;
      }

      let respuestaApi = await this.$maca.api.get(
        "/turno/obtenerTodos",
        params
      );

      this.cargando = false;

      if (respuestaApi != null) {
        this.attrs = [];
        respuestaApi.datos.forEach((fecha) => {
          let contadorTotal = 0;
          let contadorDisponibles = 0;
          let contadorOcupados = 0;
          fecha.detalleTurno.forEach((turno) => {
            turno.detalleTurnoTecnico.forEach((turnoTecnico) => {
              contadorTotal += 1;
              if (turnoTecnico.disponible) contadorDisponibles += 1;
              else contadorOcupados += 1;
            });
          });
          let item = {
            dates: fecha.fecha.replace(/-/g, "/"),
          };
          let porcentajeDisponible =
            (contadorDisponibles * 100) / contadorTotal;
          if (porcentajeDisponible >= 75) {
            item.highlight = {
              color: "green",
              fillMode: "light",
            };
          } else if (porcentajeDisponible >= 50) {
            item.highlight = {
              color: "yellow",
              fillMode: "light",
            };
          } else if (porcentajeDisponible >= 25) {
            item.highlight = {
              color: "orange",
              fillMode: "light",
            };
          } else if (porcentajeDisponible < 25) {
            item.highlight = {
              color: "red",
              fillMode: "light",
            };
          }
          this.attrs.push(item);
        });
      } else {
      }
    },
    async obtenerRangoRetiro() {
      this.cargando = true;
      let params = {};

      if (this.seccion) {
        params.seccionID = this.seccion.id;
      }

      let respuestaApi = await this.$maca.api.get(
        "/rangoRetiro/obtenerTodos",
        params
      );

      this.cargando = false;

      if (respuestaApi != null) {
        respuestaApi.datos.forEach((fecha) => {
          let item = {
            dates: fecha.fecha.replace(/-/g, "/"),
            dot: "purple",
          };

          this.attrs.push(item);
        });
      } else {
      }
    },
    async obtenerMetricas() {
      this.cargandoMetrica = true;

      let params = {};

      if (this.seccion) {
        params.seccionID = this.seccion.id;
      }

      let respuestaApi = await this.$maca.api.get(
        "/turno/obtenerMetricas",
        params
      );

      this.cargandoMetrica = false;

      if (respuestaApi != null) {
        //cargo la barra de clientes nuevos
        this.chartDataBar = {
          labels: [],
          datasets: [
            {
              label: "Clientes nuevos",
              data: [],
              backgroundColor: [],
            },
          ],
        };
        this.chartDataBar.labels = [];
        this.chartDataBar.datasets.data = [];
        await respuestaApi.datos.clientesNuevos.forEach((metricaClientes) => {
          this.chartDataBar.labels.push(metricaClientes.mesNombre);
          this.chartDataBar.datasets[0].data.push(
            metricaClientes.cantidad + ""
          );
          this.chartDataBar.datasets[0].backgroundColor.push(
            this.colorRandom()
          );
        });

        //carga la torta de turnos
        this.chartDataPie = {
          labels: ["T. diponibles/mes", "T. ocupados/mes"],
          datasets: [
            {
              label: "Cantidad de turnos",
              data: [],
              backgroundColor: [this.colorRandom(), this.colorRandom()],
            },
          ],
        };
        this.chartDataPie.datasets[0].data.push(
          respuestaApi.datos.cantidadTurnos.cantidadDisponibles + ""
        );
        this.chartDataPie.datasets[0].data.push(
          respuestaApi.datos.cantidadTurnos.cantidadOcupados + ""
        );
        this.metricas = respuestaApi.datos;
      }
    },
    colorRandom() {
      //colores random pastel
      return (
        "hsl(" +
        360 * Math.random() +
        "," +
        (25 + 70 * Math.random()) +
        "%," +
        (70 + 10 * Math.random()) +
        "%)"
      );
    },
  },
  watch: {
    seccion() {
      this.obtenerTodosLosTurnos();
      this.obtenerMetricas();
    },
  },
};
</script>

<style scoped>
.circuloVerde {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #c9e9da;
}
.circuloAmarillo {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #eff1c9;
}
.circuloNaranja {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #efe5cf;
}
.circuloRojo {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #efd7da;
}
</style>
